import { Injectable } from '@angular/core';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class MapLoaderService {

  constructor() { }

  private static promise;

  load(apiKey) {
    if (!MapLoaderService.promise) { // load once
      MapLoaderService.promise = new Promise((resolve) => {
        window['__onGapiLoaded'] = (ev) => {
          resolve(window.gapi);
        }
        const node = document.createElement('script');
        node.src = 'https://maps.google.com/maps/api/js?libraries=places&key=' + apiKey;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return MapLoaderService.promise;
  }
}
