import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire//compat/firestore';
import { format, parseISO } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AngularFireService {

  firestoreSubscription: Subscription;
  restaurantIds: number[] = [];  // list of restaurant ids loaded in the page
  singleRestaurantIsClosed = false;
  closedRestaurants: number[] = [];
  globalClosed = false;
  closedStatus = [];
  openingInStatus = [];
  interval = [];
  isClosedDetails = [];       // list of restaurants and time details which may or may not be closed
  isOpenDetails = [];       // list of restaurants and time details which may or may not be open

  constructor(
    private commonService: CommonService,
    private ngFirestore: AngularFirestore
  ) { }

  // query firestore for restaurants open/close
  firestoreRestaurantOpenStatus(branchId) {
    this.firestoreSubscription = this.restaurantOpenStatus(+branchId).subscribe(res => {
      if (res.length) this.processRestaurantsClose(res);
    });
  }

  // process restaurant open/close
  processRestaurantsClose(res) {
    // console.log('WATCHING RESTAURANT', res);
    const todayNumber = this.commonService.getTodayNumber();
    const timenow = this.commonService.getTimeAsInteger();

    this.closedRestaurants = [];

    for (const resTime of res[0].timings) {
      if (resTime['is_manually_close'] === undefined) continue;

      if (resTime['is_manually_close'] === false) {
        if (!Object.prototype.hasOwnProperty.call(resTime, 'restaurant_time')) continue;     // restaurant time not set

        // filter only today's custom hours
        let todayTime = resTime['restaurant_time'].filter(item => item.day === todayNumber);
        if (!todayTime.length) continue;             // time for the day is not set, we've left it open

        let nextDayNumber;
        if (todayNumber === 6) nextDayNumber = 0;
        else nextDayNumber = todayNumber + 1;

        todayTime = todayTime[0];     // get the filtered element
        if (todayTime['is_open'] === true) {
          if (todayTime['has_custom_hours'] === true) {
            let is_open = false;

            let totalCustomHrs = todayTime['custom_hours'].length;

            for (const [cus_hr_index, cus_hr] of todayTime['custom_hours'].entries()) {  // loop through the time periods (Eg.: 9am-1:30pm; 2pm-7pm)
              if (timenow >= cus_hr['start'] && timenow < cus_hr['end']) {
                is_open = true;
                this.checkToClose(resTime['restaurant_id'], timenow, cus_hr['end']);
                break;
              } else {
                // NOTE: BELOW CODE WORKS. BUT HAS BEEN COMMENTED OUT IN ORDER TO IMPROVE PERFORMANCE! ERROR AFTER UNCOMMENT WILL BE BECAUSE OF ABOVE COMMENTED VARIABLES.

                // if (totalCustomHrs === 1) {
                //   if (timenow < cus_hr['start']) {        // Less than first open time of the day
                //     console.log('LESS THAN MORNING OPEN TIME');   // CAN SHOW OPENING IN IF LESS THAN 60 mins or so
                //     this.checkToOpen(resTime['restaurant_id'], timenow, cus_hr['start']);
                //   } else {
                //     console.log('MORE THAN CLOSING TIME OF THE DAY');     // CAN SHOW TOMORROW'S OPENING TIME
                //     let nextDayTime = resTime['restaurant_time'].filter(item => item.day === nextDayNumber);
                //     if (!nextDayTime.length)  break;    // can be break or continue. This is last iteration of thie custom_hour anyway

                //     nextDayTime = nextDayTime[0];
                //     if (nextDayTime['is_open']) {    // if there is custom time for next day and is open
                //       const t = nextDayTime['custom_hours'][0]['start'];
                //       this.openingInStatus[resTime['restaurant_id']] = {text: 'Opens tomorrow at ', counter: this.commonService.getIntegerAsTime(t)};
                //     }
                //   }
                // } else {
                //   if (timenow < cus_hr['start']) {        // Less than opening time
                //     console.log('LESS THAN CUS HR OPEN TIME');   // CAN SHOW OPENING IN IF LESS THAN 60 mins or so
                //     this.checkToOpen(resTime['restaurant_id'], timenow, cus_hr['start']);
                //     break;       // No need to check the other custom hours of the day
                //   }
                //   if (cus_hr_index === totalCustomHrs - 1) {      // last custom hour set of the day
                //     console.log('MORE THAN CUS HR CLOSING TIME')      // CAN SHOW TOMORROW'S OPENING TIME
                //     let nextDayTime = resTime['restaurant_time'].filter(item => item.day === nextDayNumber);
                //     if (!nextDayTime.length)  break;    // can be break or continue. This is last iteration of thie custom_hour anyway

                //     nextDayTime = nextDayTime[0];
                //     if (nextDayTime['is_open']) {    // if there is custom time for next day and is open
                //       const t = nextDayTime['custom_hours'][0]['start'];
                //       this.openingInStatus[resTime['restaurant_id']] = {text: 'Opens tomorrow at ', counter: this.commonService.getIntegerAsTime(t)};
                //     }
                //   }
                // }
              }
            }

            if (!is_open) this.closedRestaurants.push(resTime['restaurant_id']);
            // else retaurant is open
          } // else retaurant is open
        } else this.closedRestaurants.push(resTime['restaurant_id']);
      } else this.closedRestaurants.push(resTime['restaurant_id']);
    }
  }

  watchOrder(restaurantId, orderId): Observable<unknown[]> {
    return this.ngFirestore
      .collection('orders_by_restaurant_id/' + restaurantId + '/orders', ref => ref.where('order_id', '==', ''+orderId))
      .valueChanges();
  }

  restaurantOpenStatus(branchId): Observable<unknown[]> {
    return this.ngFirestore
      .collection('open_status_by_restaurant_id', ref => ref.where('branch_id', '==', branchId))
      .valueChanges();
  }

  watchCartItems(restaurantId): Observable<unknown[]> {
    return this.ngFirestore
      .collection('products', ref => ref.where('restaurant_id', 'in', [+restaurantId, ''+restaurantId]))   // using 'in' here so as to match either number/string
      .valueChanges();
  }

  // to be called when restaurant is open; to check if closing time is near; if near, start countdown timer
  checkToClose(resId, timenow, endtime) {
    // this formula for calculating timediff of format 1400, 1650 etc.
    const diff = (endtime - timenow) - (40 * (Math.floor(endtime / 100) - Math.floor(timenow / 100)));

    // first clear interval if already set. This will avoid running the same counter for the same restaurant. Happens when time gets updated while timer is already running
    if (typeof this.interval[resId] !== 'undefined') clearInterval(this.interval[resId]);

    // clear closed status also
    delete this.closedStatus[resId];

    if (diff < 60) {    // if less than 60 mins
      if (diff > 30) {
        const now = (new Date()).getTime();
        const target = now + (diff * 60 * 1000);
        const d = (new Date(target)).toISOString();
        this.closedStatus[resId] = {text: 'Closing soon', counter: ' | Order before ' + format(parseISO(d), 'hh:mm a')};
      } else {          // if less than 30 mins
        this.startCountdown(resId, diff);
      }
    }
  }

  // to be called when restaurant is closed; to check if opening time is near; if near, start countdown timer
  checkToOpen(resId, timenow, starttime) {
    // this formula for calculating timediff of format 1400, 1650 etc.
    const diff = (starttime - timenow) - (40 * (Math.floor(starttime / 100) - Math.floor(timenow / 100)));

    // first clear interval if already set. This will avoid running the same counter for the same restaurant. Happens when time gets updated while timer is already running
    if (typeof this.interval[resId] !== 'undefined') clearInterval(this.interval[resId]);

    // clear openingIn status also
    delete this.openingInStatus[resId];

    if (diff < 60) {    // if less than 60 mins
      if (diff > 30) {
        const now = (new Date()).getTime();
        const target = now + (diff * 60 * 1000);
        const d = (new Date(target)).toISOString();
        this.openingInStatus[resId] = {text: 'Closed', counter: ' | Opens at ' + format(parseISO(d), 'hh:mm a')};
      } else {          // if less than 30 mins
        this.startCountup(resId, diff);
      }
    }
  }

  // count down to restaurant closed
  startCountdown(resId, diff) {
    const now = (new Date()).getTime();
    const target = now + (diff * 60 * 1000);
    let millisec = target - now;

    this.interval[resId] = setInterval(() => {
      millisec -= 1000;
      if (millisec < 1000) {    // if interval has lapsed
        clearInterval(this.interval[resId]);
        delete this.interval[resId];    // delete the interval key
        this.closedRestaurants.push(resId);    // add the restaurant to the closed list
      }
      const min = String(Math.floor((millisec % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const sec = String(Math.floor((millisec % (1000 * 60)) / 1000)).padStart(2, '0');

      this.closedStatus[resId] = {text: 'Closing in ', counter: min + ' mins ' + sec + ' sec'};
    }, 1000);
  }

  // count up to restaurant open
  startCountup(resId, diff) {
    const now = (new Date()).getTime();
    const target = now + (diff * 60 * 1000);
    let millisec = target - now;

    this.interval[resId] = setInterval(() => {
      millisec -= 1000;
      if (millisec < 1000) {    // if interval has lapsed
        clearInterval(this.interval[resId]);
        delete this.interval[resId];    // delete the interval key
        this.closedRestaurants = this.closedRestaurants.filter(el => !resId);    // since the restaurant is now open, remove it from the closed list
      }
      const min = String(Math.floor((millisec % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const sec = String(Math.floor((millisec % (1000 * 60)) / 1000)).padStart(2, '0');

      this.closedStatus[resId] = {text: 'Opening in ', counter: min + ' mins ' + sec + ' sec'};
    }, 1000);
  }

}
