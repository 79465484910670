import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.page.html',
  styleUrls: ['./splash-screen.page.scss'],
})
export class SplashScreenPage {

  @Input() modalController: ModalController

  show = false;
  done = false;

  constructor(
    private commonService: CommonService
  ) { }

  ionViewDidEnter() {
    this.commonService.statusBarInit();

    this.startSplash();
  }

  async startSplash() {
    await SplashScreen.hide();

    // failsafe incase modal does not get closed
    setTimeout(() => {
      if (!this.done) this.modalController.dismiss();
    }, 6000);

    setTimeout(() => {
      this.show = true;
      setTimeout(() => {
        this.done = true;
        this.modalController.dismiss();
      }, 2500);
    }, 300);
  }

}
