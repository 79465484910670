import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Animation, AnimationController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  globalTimeSubscription: Subscription;
  globalClosed = false;
  interval;
  tabHeight: number;
  tabIsHidden = false;
  cdn: string;
  fullStartTime;
  fullEndTime;
  preselectedCoupon = null;
  listenToProductsStock = false;      // whether to listen to firestore product stock or not. If not, it'll only consider the product.stock boolean sent by API in restaurant page
  showSubscription = false;
  isMember = false;
  closingIn = null;
  reorderFlag = false;        // this is used instead of navigation extra because using navigation extra was causing a bug.
  // when redirected to cart page and then going back to home page, it was getting redirected back to cart page again because
  // this.activatedRoute.queryParams.subscribe was still active (and you can't unsub to this unless home page is destroyed)

  justOrdered = false;        // flag used for fixing navigation error right after order placed

  constructor(
    private ngFirestore: AngularFirestore,
    private animationCtrl: AnimationController,
  ) { }

  // watch for branch open/close
  watchGlobalTiming(branchId) {
    this.globalTimeSubscription = this.subToFirestoreGlobalTime(branchId).subscribe(res => {
      clearInterval(this.interval);
      this.closingIn = null;

      // console.log('WATCHING GLOBAL', res);

      if (!res.length) {
        this.globalClosed = true;
        return;
      }

      const data = res[0];

      if (data['is_manually_close']) {
        this.globalClosed = true;
        return;
      }

      const startTime = data['start_time'].split(':');
      const globalStartHr = +startTime[0];
      const globalStartMin = +startTime[1];
      const endTime = data['end_time'].split(':');
      const globalEndHr = +endTime[0];
      const globalEndMin = +endTime[1];

      const nowHr = (new Date()).getHours();
      const nowMin = (new Date()).getMinutes();
      const nowSec = (new Date()).getSeconds();

      const anyDate = '2022-01-01 ';
      this.fullStartTime = ((new Date(anyDate + globalStartHr + ':' + globalStartMin + ':00')).getTime())/1000;
      this.fullEndTime = ((new Date(anyDate + globalEndHr + ':' + globalEndMin + ':00')).getTime())/1000;
      const fullNowTime = ((new Date(anyDate + nowHr + ':' + nowMin + ':' + nowSec)).getTime())/1000;

      if (fullNowTime < this.fullStartTime) {
        // not yet open
        this.globalClosed = true;
        // now check for gap
        const countUp = this.fullStartTime - fullNowTime;

        // if to open time is less than 5 mins to go
        if (countUp < 300) this.startCounter(countUp, false);    // false means open when counter expires
      } else if (fullNowTime >= this.fullEndTime) this.globalClosed = true;
      else {
        this.globalClosed = false;
        // now check for gap
        const countDown = this.fullEndTime - fullNowTime;

        // if to close time is less than 15 mins to go
        if (countDown < 900) this.startCounter(countDown, true);    // true means close when counter expires
      }
    });
  }

  subToFirestoreGlobalTime(branchId) {
    return this.ngFirestore
      .collection('global_timing_by_branch_id', ref => ref.where('branch_id', '==', +branchId))
      .valueChanges();
  }

  startCounter(counter: number, action: boolean) {
    this.interval = setInterval(() => {
      counter--;
      this.closingIn = {text: 'Closing in ', counter: Math.floor(counter/60) + ' min ' + counter%60 + ' sec'};
      if (counter <= 0) {
        clearInterval(this.interval);
        this.closingIn = null;
        this.globalClosed = action;
      }
    }, 1000);
  }

  // save tabheight
  setTabHeight() {
    if (this.tabHeight) return;       // if already set, then skip

    if (this.tabHeight === 0 || this.tabHeight < 10 || !this.tabHeight || this.tabHeight === undefined) {
      // this delay is required because otherwise the tab height value comes out incorrect
      setTimeout(() => {
        this.tabHeight = document.getElementById('myTabBar').offsetHeight - 1;    // subtract -1 for actual height

        // fall back to a default value in case we're still not able to get tab height (10 is any random number close to 10)
        if (this.tabHeight === 0 || this.tabHeight < 10 || !this.tabHeight || this.tabHeight === undefined) this.tabHeight = 56;
      }, 1000);
    }
  }

  public hideTabs() {
    if (!this.tabHeight || this.tabHeight === undefined) return;      // do nothing if value is not set

    if (this.tabIsHidden) return;

		this.animateHeight(this.tabHeight + 'px', '0', true);

    this.tabIsHidden = true;
	}

	public async showTabs() {
    if (!this.tabHeight || this.tabHeight === undefined) return;      // do nothing if value is not set
    if(!this.tabIsHidden) return;
		this.animateHeight('0', this.tabHeight + 'px', false);

    this.tabIsHidden = false;
	}

	async animateHeight(from, to, isHide) {
    const animateHeight: Animation = this.animationCtrl.create()
    .addElement(document.querySelector('#myTabBar'))
    .duration(100)
    .fromTo('height', from, to);

		let transform: Animation;
    
    if (isHide) {
      transform = this.animationCtrl.create()
			.addElement(document.querySelector('#myTabBar'))
			.duration(100)
      .fromTo('transform', 'translateY(0)', 'translateY(100%)');

      await transform.play();
      await animateHeight.play();
    } else {
      transform = this.animationCtrl.create()
			.addElement(document.querySelector('#myTabBar'))
			.duration(100)
      .fromTo('transform', 'translateY(100%)', 'translateY(0)');

      await animateHeight.play();
      await transform.play();
    }
	}
}
