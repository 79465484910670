const baseUrl = 'https://app.myfoodwifi.com/api/';

// check phone url
export const checkPhoneUrl = baseUrl + 'sites/checkphone';
//login send number
export const loginsendOtp = baseUrl + 'sites/applogin';
//login verify otp
export const loginverifyOtp = baseUrl + 'sites/checkphone2';
// create profile url
export const createProfileUrl = baseUrl + 'profiles';
// home page top sliders
export const topSlidersUrl = baseUrl + 'sites/getslider';
// home page collections
export const homePageCollectionsUrl = baseUrl + 'sites/getbranchcollection';
// fetch collection page
export const collectionPageUrl = baseUrl + 'sites/getbranchcollectionbyid?id=';
// favourites url
export const favouritesUrl = baseUrl + 'favourites';
// start up message
export const startUpMsg = baseUrl + 'sites/offermsg';
// restaurant page category products
export const getRestaurantProductsUrl = baseUrl + 'restaurants/categoryproduct?id=';
// search url
export const searchUrl = baseUrl + 'restaurants/search?_search=';
// check product details url
export const getProductDetailUrl = baseUrl + 'products/productdetails?id=';
// get product url (actually variant)
export const getProductUrl = baseUrl + 'products/';
// get restaurant detail
export const getRestaurantUrl = baseUrl + 'restaurants';
// fetch all restaurants
export const allRestaurantsUrl = baseUrl + 'restaurants/restaurantlist';
// get branch id from lat lng
export const getBranchIdUrl = baseUrl + 'sites/getbranchid?geo_location=';
// cart url
export const cartUrl = baseUrl + 'carts';
// check if user has items in cart (to be called only if local storage cart is empty)
export const cartHasItemsUrl = baseUrl + 'carts/hasitems';
// get addresses url
export const addressUrl = baseUrl + 'shippings';
// get voucher codes from cart page
export const cartVoucherUrl = baseUrl + 'carts/voucherlist';
// check custom voucher code
export const checkVoucherUrl = baseUrl + 'carts/vouchercheck?voucher_code=';
// orders url
export const ordersUrl = baseUrl + 'orders';
// current orders only url
export const currentOrdersUrl = baseUrl + 'orders/currentonly';
// note token url
export const noteTokenUrl = baseUrl + 'notetokens';
// feedbacks url
export const feedbackUrl = baseUrl + 'feedbacks/';
// cashbacks url
export const cashbackUrl = baseUrl + 'cashbacks';
// get order details page banner
export const orderDetailsBannerUrl = baseUrl + 'sites/getorderdetailbanner?id=';        // order id not actually used. Simple sending it just in case...
// fetch ads
export const adsUrl = baseUrl + 'sites/ad?id=';        // order id not actually used. Simple sending it just in case...
// people also ordered
export const alsoOrderedUrl = baseUrl + 'products/relatedproduct?id=';
// get search filters
export const getFilterOptionsUrl = baseUrl + 'restaurants/searchfilter';
// fetch home page notice
export const fixedNoticeUrl = baseUrl + 'sites/getnote';
// fetch restaurant special items and today's offers
export const restaurantSpecialItemsUrl = baseUrl + 'restaurants/getspecialitem?id=';
// fetch restaurant special items and today's offers
export const restaurantTodaysOfferUrl = baseUrl + 'restaurants/getofferitem?id=';
// fetch restaurant reviews
export const restaurantReviewsUrl = baseUrl + 'feedbacks/restaurantreviews?id=';
// fetch company phone
export const phoneUrl = baseUrl + 'sites/getcompanyphone';
// fetch restaurant branches
export const restaurantBranchesUrl = baseUrl + 'restaurants/branch?store_chain_id=';
// fetch subscription plans
export const subscriptionPlansUrl = baseUrl + 'subscriptions';
// fetch my subscription plan
export const membershipUrl = baseUrl + 'subscriptions/membership';
// confirm subscription payment
export const confirmSubscriptionUrl = baseUrl + 'subscriptions/confirm';
// cancel subscription
export const cancelSubscriptionUrl = baseUrl + 'subscriptions/cancel';
// get restaurant banner
export const restaurantBannerUrl = baseUrl + 'sites/getbanner?id=';
// get restaurant reviews
export const singleRestaurantReviewsUrl = baseUrl + 'restaurants/reviewlist?id=';
// reorder (rebuild cart)
export const reorderUrl = baseUrl + 'carts/reorder?order_id=';
// check app version
export const appVersionUrl = baseUrl + 'sites/appversion?device=';
// receipt download url
export const receiptUrl = baseUrl + 'orders/export?id=';
// port user from v2 to v3
export const portUserUrl = baseUrl + 'sites/portuser';
// settings - maps api key
export const settingsUrl = baseUrl + 'settings';
