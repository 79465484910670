import { ApiParams } from './../models/api-params';
import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rest } from '../models/rest';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private authService: AuthService
  ) {
  }

  post(params: ApiParams): Observable<any> {
    return this.http.post(params.url, params.data, this.httpHeader(params.token, params.branchId)).pipe(
      tap(
        res => { },
        err => {
          this.commonService.presentToast(err.statusText, 3000, 'warning');
          if (err.status === 403) {
            this.authService.logout();
          }
        }
      )
    );
  }

  patch(params: ApiParams): Observable<Rest> {
    return this.http.patch(params.url, params.data, this.httpHeader(params.token, params.branchId)).pipe(
      tap(
        res => { },
        err => {
          this.commonService.presentToast(err.statusText, 3000, 'warning');
          if (err.status === 403) {
            this.authService.logout();
          }
        }
      )
    );
  }

  delete(params: ApiParams): Observable<Rest> {
    return this.http.delete(params.url, this.httpHeader(params.token, params.branchId)).pipe(
      tap(
        res => { },
        err => {
          this.commonService.presentToast(err.statusText, 3000, 'warning');
          if (err.statusCode === 403) {
            this.authService.logout();
          }
        }
      )
    );
  }

  getObject(params: ApiParams): Observable<Rest> {
    return this.http.get(params.url, this.httpHeader(params.token, params.branchId)).pipe(
      tap(
        res => { },
        err => {
          this.commonService.presentToast(err.statusText, 3000, 'warning');
          if (err.statusCode === 403) {
            this.authService.logout();
          }
        }
      )
    );
  }

  getList(params: ApiParams): Observable<Rest[]> {
    return this.http.get(params.url, this.httpHeader(params.token, params.branchId)).pipe(
      tap(
        (res: Rest[]) => { },
        err => {
          this.commonService.presentToast(err.statusText, 3000, 'warning');
          if (err.statusCode === 403) {
            this.authService.logout();
          }
        }
      ),
    );
  }

  downloadPdf(params: ApiParams): Observable<Blob> {
    let headers = new HttpHeaders({
      'Accept': 'application/pdf',
      'Authorization': 'Bearer ' + params.token,
      'Branchid': params.branchId
    });
    return this.http.get(params.url, { headers: headers, responseType: 'blob' });
  }

  httpHeader(token: string, branchId?: string) {
    branchId = branchId ? '' + branchId : '1';
    token = token ? token : '123';
    const options = token ? { 'Content-Type': 'application/json', 'Branchid': branchId, 'Authorization': 'Bearer ' + token } : { 'Content-Type': 'application/json', 'Branchid': branchId };
    return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Branchid': branchId, 'Authorization': 'Bearer ' + token }) };
  }
}
