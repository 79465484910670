import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { SplashScreenPage } from './home/splash-screen/splash-screen.page';
import { MapLoaderService } from './services/map-loader.service';
import { NotificationsService } from './services/notifications.service';
import { SharedService } from './services/shared.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AngularFireService } from './services/angular-fire.service';
import { ApiService } from './services/api.service';
import { settingsUrl } from './api-endpoints';

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  closed$ = new Subject<any>();
  hasNetwork = true;
  pwa;

  constructor(
    private notificationService: NotificationsService,
    private authService: AuthService,
    private modalController: ModalController,
    private storage: Storage,
    private mapLoaderService: MapLoaderService,
    private sharedService: SharedService,
    private router: Router,
    private platform: Platform,
    private network: Network,
    private angularFireService: AngularFireService,
    private apiService: ApiService
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
      }
    });
   }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.showSplashscreen();

      this.bootstrap();

      this.tabService();

      this.watchNetwork();
    });
    if(this.platform.is('desktop' || 'pwa' || 'mobileweb')) {
      // console.log('pwa app');
      this.pwa = true;

    }
  }

  async showSplashscreen() {
    const modal = await this.modalController.create({
      component: SplashScreenPage,
      componentProps: {
        modalController: this.modalController,
      },
      cssClass: 'full-page-modal',
      animated: false
    });

    return await modal.present();
  }

  async bootstrap() {
    this.sharedService.cdn = 'https://globizs.sgp1.cdn.digitaloceanspaces.com/foodwifi/';

    this.apiService.getObject({
      url: settingsUrl,
      branchId: 1,    // any branch
      token: 'myfoodwifi-' + Math.round(Math.random() * 1000)   // just any number
    }).subscribe(res => {
      this.mapLoaderService.load(res.maps_api_key);
    });

    await this.storage.create();

    this.notificationService.token = await this.authService.getToken();
    this.notificationService.initPush();

    const branchId = await this.authService.getBranch();
    if (branchId) {
      this.sharedService.watchGlobalTiming(branchId);
      this.angularFireService.firestoreRestaurantOpenStatus(branchId);
    }
  }

  // subscribe to route changes so that we can hide/show tabs depending on the routes
  tabService() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      takeUntil(this.closed$)
    ).subscribe(event => {
      if (event['url'] === '/tabs/home' || event['url'] === '/tabs/orders' || event['url'] === '/tabs/account') this.sharedService.showTabs();
      else {
        // to fix navigation bug after order placed
        if (this.sharedService.justOrdered && (event['url'].match(/cart/) || event['url'].match(/restaurant/))) return;

        this.sharedService.hideTabs();
      }
    });
  }

  watchNetwork() {
    this.network.onDisconnect().subscribe(() => {
      this.hasNetwork = false;
    });

    this.network.onConnect().subscribe(() => {
      this.hasNetwork = true;
    });
  }

}
