import { format, parseISO } from 'date-fns';
import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
  ) { }

  async presentLoading(message = 'Loading...') {
    const loading = await this.loadingController.create({
      spinner: 'dots',
      message
    });
    await loading.present();
  }

  dismissLoading() {
    this.loadingController.dismiss();
  }

  async presentToast(msg: string, dur: number = 2000, col: string = 'dark') {
    const toast = await this.toastController.create({
      cssClass: 'custom-toast-pos',
      message: msg,
      duration: dur,
      // color: col,
      color: 'toastcolor'
    });
    toast.present();
  }

  dateTimeFormat(date, withTime = false, withDay = true) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'No', "Dec"];
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dateObj = new Date(date);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const dayName = days[dateObj.getDay()];

    if (withTime) {
      let hours = dateObj.getHours();
      let minutes = dateObj.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const mins = minutes < 10 ? '0' + minutes : minutes;
      const strTime = hours + ':' + mins + ' ' + ampm;
      return strTime + ", " + dayName + ", " + day + " " + month + ", " + year;
    } else {
      return withDay ? dayName + ", " + day + " " + month + ", " + year : day + " " + month + ", " + year;
    }
  }

  async statusBarInit() {
    try {
      await NavigationBar.show();
      await StatusBar.setOverlaysWebView({ overlay: true });
      await StatusBar.setStyle({ style: Style.Light });
    } catch(e) {
      // console.log('Statusbar not supported');
    }
  }

  // Use this function to request overlay again in case it doesn't work in some phones
  // statusBarOverlay(overlay: boolean) {
  //     StatusBar.setOverlaysWebView({ overlay: true });
  //     StatusBar.setStyle({ style: Style.Light });
  // }

  getTodayNumber() {
    return (new Date()).getDay();
  }

  // convert 10:30 to 1030
  getTimeAsInteger() {
    const date = new Date();
    const time = date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0');   // convert to string first to allow concatenation
    return +time;   // return as number by adding '+' symbol
  }

  // convert 1030 to 10:30
  getIntegerAsTime(t) {
    const hr = Math.floor(t/100);
    return hr.toString() + ':' + (t%100).toString().padStart(2, '0') + ' ' + (hr > 12 ? 'pm' : 'am');
  }

  // convert yyyy-mm-dd H:i to Human readable format
  dateToHuman(date, hasTime = true) {
    const dt = new Date(date);
    const dayName = dt.getDay();
    const d = dt.toISOString();
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return hasTime ? dayNames[dayName] + ', ' + format(parseISO(d), 'hh:mm a, d MMMM yyyy') : dayNames[dayName] + ', ' + format(parseISO(d), 'd MMMM yyyy');
  }

  // time difference calculator between now and futureTime. If futureTime is only time (without date), suffixDate should be passed
  // futureTime should be in Y-m-d or Y-m-d H:i format
  // returns human readable time format
  staticTimeDiff(now: Date, futureTime: Date, noSeconds = false) {
    let millisec = futureTime.getTime() - now.getTime();

    let delayed = false;
    if (millisec <= 0) {
      millisec *= -1;
      delayed = true;
    }

    const days = Math.floor(millisec / (1000 * 60 * 60 * 24));
    const hr = String(Math.floor((millisec % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
    const min = String(Math.floor((millisec % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
    const sec = String(Math.floor((millisec % (1000 * 60)) / 1000)).padStart(2, '0');

    let daysTxt;
    if (days < 1) daysTxt = '';
    else daysTxt = days === 1 ? days + ' day, ' : days + ' days, ';

    const timerText = noSeconds ? daysTxt + hr + ':' + min : daysTxt + hr + ':' + min + ':' + sec;

    return {
      timerText,
      delayed
    };
  }
}
