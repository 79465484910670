import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { Rest } from '../models/rest';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { checkPhoneUrl, loginverifyOtp } from './../api-endpoints';

const TOKEN_KEY = 'myfoodwifi-token';
const BRANCH_KEY = 'myfoodwifi-branch_id';
const PHONE_KEY = 'myfoodwifi-phone_no';
const LOCATION_KEY = 'myfoodwifi-location';
const SHIPPING_KEY = 'myfoodwifi-shipping';
const USERID_KEY = 'myfoodwifi-userid';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public user: Observable<any>;

  constructor(
    private http: HttpClient,
    private plt: Platform,
    private storage: Storage,
    private router: Router,
  ) {
    this.loadStoredToken();
  }

  loadStoredToken() {
    const platformObs = from(this.plt.ready());

    this.user = platformObs.pipe(
      switchMap(() => from(this.storage.get(TOKEN_KEY))),
      map(token => token ? true : null)
    );
  }

  checkAccount(token, phoneNo): Observable<Rest> {
    return this.http.post(checkPhoneUrl, {token}).pipe(
      tap(
        async (res: Rest) => {
          await this.storage.set(TOKEN_KEY, res.token);
          await this.storage.set(USERID_KEY, res.id);
          await this.storage.set(PHONE_KEY, phoneNo);
        }
      )
    );
  }

  verifyOtp(data, phoneNo): Observable<Rest> {
    return this.http.post(loginverifyOtp, data).pipe(
      tap(
        async (res: Rest) => {
          await this.storage.set(TOKEN_KEY, res.token);
          await this.storage.set(USERID_KEY, res.id);
          await this.storage.set(PHONE_KEY, phoneNo);
        }
      )
    );
  }
  async logout() {
    localStorage.removeItem('Token');   // remove v2 token

    await this.storage.clear();

    this.router.navigateByUrl('/login', { replaceUrl: true });
  }

  getToken() {
    // this.storage.set(TOKEN_KEY, "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1aWQiOjIyNTAyLCJpYXQiOjE2NjUzODc2ODR9.wgi7bOlJ3gZcRieKifUnjsF0YDebOQs7Zx3yaW4DLaHZxFals_MtP6ho5KqQ4qPLK-ms420rDAPVOmZ0YCaQIg")
    return this.storage.get(TOKEN_KEY);
  }

  getBranch() {
    return this.storage.get(BRANCH_KEY);
  }

  getPhone() {
    return this.storage.get(PHONE_KEY);
  }

  getLocation() {
    return this.storage.get(LOCATION_KEY);
  }

  getShippingdata() {
    return this.storage.get(SHIPPING_KEY);
  }

  async setBranch(branchId) {
    return await this.storage.set(BRANCH_KEY, branchId);
  }

  async setLocationText(lat, lng, locationText, addressName = null) {
    return await this.storage.set(LOCATION_KEY, {lat, lng, locationText, addressName});
  }

  async setShippingdata(shippingdata) {
    return await this.storage.set(SHIPPING_KEY, shippingdata);
  }

  async clearShippingaddress() {
    await this.storage.remove(SHIPPING_KEY);
  }
}
