import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { Storage } from '@ionic/storage-angular';
import { noteTokenUrl } from '../api-endpoints';
import { ApiService } from './api.service';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })

export class NotificationsService {

  token: string;

  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private storage: Storage,
    private router: Router
  ) { }

  initPush() {
    if (Capacitor.getPlatform() !== 'web') this.registerPush()
  }

  private registerPush() {
    PushNotifications.requestPermissions().then(permission => {
      if (permission.receive === 'granted') PushNotifications.register()
    });

    PushNotifications.addListener('registration', async newFcmToken => {
      const oldFcmToken = await this.storage.get('FCM_TOKEN');

      if (this.token) {
        if (oldFcmToken === newFcmToken.value) {
          return;   // no need to send same token again
        }

        this.apiService.post({
          url: noteTokenUrl,
          token: this.token,
          data: {note_token: newFcmToken.value, app_type: 1}
        }).subscribe(async res => {
          await this.storage.set('FCM_TOKEN', newFcmToken.value);
        });
      }
    });

    PushNotifications.addListener('registrationError', err => console.log('ERROR: ' + err));

    PushNotifications.addListener('pushNotificationReceived', notifications => {
      this.commonService.presentToast(notifications.body, 4000, 'success');
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        if (notification && notification.notification && notification.notification.data) {
          const data = notification.notification.data;
          if (data && data.type) {
            if (data.type === 'restaurant') {
              const navigationExtras: NavigationExtras = { state: { notificationTap: true, branchId: data.branch_id }, replaceUrl: true };
              this.router.navigate(['/tabs/home/restaurant/' + data.restaurant_id], navigationExtras);
            } else if (data.type === 'product') {
              const navigationExtras: NavigationExtras = { state: { notificationTap: true, branchId: data.branch_id }, replaceUrl: true };
              this.router.navigate(['/tabs/home/product/' + data.product_id], navigationExtras);
            } else if (data.type === 'order') {
              const navigationExtras: NavigationExtras = { state: { notificationTap: true, branchId: data.branch_id }, replaceUrl: true };
              this.router.navigate(['/tabs/orders/' + data.order_id], navigationExtras);
            }
          }
        }
      }
    );
  }
}
