// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBrCtBBmANMM0IdYlFSb6sno0X9QHHI_pE",
    authDomain: "foodwifi-v2.firebaseapp.com",
    //databaseURL: "https://foodwifi-v2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "foodwifi-v2",
    storageBucket: "foodwifi-v2.appspot.com",
    messagingSenderId: "438354835487",
    appId: "1:438354835487:web:c840d1c7bf705e4fb6bd2a",
    measurementId: "G-BR67SL57WH"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
